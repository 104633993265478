.headtext22 {
  display: flex;
  justify-content: start;
  padding-left: 6rem;
  padding-top: 3.5rem;
}
.text1 {
  font-size: 4rem;
  color: #eb4bb6;
  font-weight: bold;
  text-align: center;
}
.text2 {
  font-size: 4rem;
  color: #1967ff !important;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  pointer-events: none;
}
.textsmall {
  color: #eb4bb6 !important;
  font-weight: bold;
  padding-top: 2rem;
  padding-left: 0.5rem;
}
.headtext2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.textsmall2 {
  color: #eb4bb6 !important;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  text-decoration: none;
  margin-left: 1rem;
  pointer-events: none;
}
.text12 {
  font-size: 4rem;
  color: #eb4bb6 !important;
  font-weight: bold;
  text-align: center;
  padding-left: 1rem;
  text-decoration: none;
  pointer-events: none;
}
.text22 {
  font-size: 2.75rem;
  color: #1967ff;
  font-weight: bolder;
  padding-left: 1rem;
  padding-top: 18rem;
}
.paratext {
  font-size: 1.8rem;
  padding-left: 1rem;
  padding-top: 2rem;
  color: #aba9bc;
  font-weight: 500;
}
.paratext1 {
  font-size: 1.8rem;
  padding-top: 2rem;
  color: #eb4ab5 !important;
  font-weight: 500;
  text-decoration: none;
  pointer-events: none;
}
.paratext2 {
  font-size: 1.8rem;
  padding-top: 2rem;
  color: #1866fe;
  font-weight: 500;
  text-decoration: none;
}
.img1 {
  margin-top: 10rem;
  width: 92%;
}
.img2 {
  position: absolute;
  margin-left: -27rem;
  width: 32rem;
}
.mblviewimg {
  display: none;
}
@media (max-width: 1024px) {
  .img1 {
    margin-top: 10rem;
    width: 92%;
  }
  .img2 {
    position: absolute;
    margin-left: -20rem;
    width: 25rem;
  }
  .text22 {
    font-size: 2.2rem;
    color: #1967ff;
    font-weight: bolder;
    padding-left: 1rem;
    padding-top: 18rem;
  }
  .paratext {
    font-size: 1.5rem;
    padding-left: 1rem;
    padding-top: 2rem;
    color: #aba9bc;
    font-weight: 500;
  }
  .paratext1 {
    font-size: 1.5rem;
    padding-top: 2rem;
    color: #eb4ab5;
    font-weight: 500;
    text-decoration: none;
  }
  .paratext2 {
    font-size: 1.5rem;
    padding-top: 2rem;
    color: #1866fe;
    font-weight: 500;
    text-decoration: none;
  }
}

@media (max-width: 768px) {
  .img1 {
    margin-top: 10rem;
    width: 92%;
  }
  .img2 {
    position: absolute;
    margin-left: -30rem;
    width: 35rem;
  }
  .text22 {
    font-size: 3rem;
    color: #1967ff;
    font-weight: bolder;
    padding-left: 1rem;
    padding-top: 18rem;
  }
  .paratext {
    font-size: 2rem;
    padding-left: 1rem;
    padding-top: 2rem;
    color: #aba9bc;
    font-weight: 500;
  }
  .paratext1 {
    font-size: 2rem;
    padding-top: 2rem;
    color: #eb4ab5;
    font-weight: 500;
    text-decoration: none;
  }
  .paratext2 {
    font-size: 2rem;
    padding-top: 2rem;
    color: #1866fe;
    font-weight: 500;
    text-decoration: none;
  }
}
@media (max-width: 510px) {
  .img1 {
    margin-top: 10rem;
    width: 92%;
  }
  .img2 {
    position: absolute;
    margin-left: -18rem;
    width: 20rem;
    margin-top: 2rem;
  }
  .text22 {
    display: none !important;
  }
  .paratext {
    font-size: 2rem;
    padding-left: 1rem;
    padding-top: 2rem;
    color: #aba9bc;
    font-weight: 500;
    text-align: center;
  }
  .paratext1 {
    font-size: 2rem;
    padding-top: 2rem;
    color: #eb4ab5;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
  }
  .text1 {
    font-size: 3rem;
    color: #eb4bb6;
    font-weight: bolder;
    text-align: center;
  }
  .text2 {
    font-size: 3rem;
    color: #1967ff;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
  }
  .text12 {
    font-size: 3rem;
    color: #eb4bb6;
    font-weight: bold;
    text-align: center;
    padding-left: 1rem;
    text-decoration: none;
  }
  .textsmall2 {
    color: #eb4bb6;
    font-weight: bold;
  }
  .headtext22 {
    display: flex;
    justify-content: start;
    padding-left: 3rem;
    margin-top: 2;
    margin-bottom: 2rem;
  }
  .textsmall {
    color: #eb4bb6;
    font-weight: bolder;
    padding-top: 1.5rem;
    padding-left: 1rem;
  }
  .mblviewimg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imgmblview {
    width: 56rem;
    margin-top: -30rem;
    margin-left: 2.5rem;
  }
  .imgmblview1 {
    position: absolute;
    width: 56rem;
    margin-left: -2rem;
    margin-top: 6rem;
  }
  .imagdiv {
    display: none;
  }
  .headtext2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
  }
}
@media (max-width: 414px) {
  .imgmblview {
    width: 52rem;
    margin-top: -30rem;
    margin-left: 4rem;
  }
  .imgmblview1 {
    position: absolute;
    width: 52rem;
    margin-left: -4rem;
    margin-top: 4rem;
  }
}

@media (max-width: 390px) {
  .img1 {
    margin-top: 10rem;
    width: 92%;
  }
  .img2 {
    position: absolute;
    margin-left: -16rem;
    width: 18rem;
    margin-top: 4rem;
  }
  .imgmblview {
    width: 48.5rem;
    margin-top: -30rem;
    margin-left: 1rem;
  }
  .imgmblview1 {
    position: absolute;
    width: 48.5rem;
    margin-left: -2rem;
    margin-top: 1rem;
  }
}
@media (max-width: 320px) {
  .img1 {
    margin-top: 10rem;
    width: 92%;
  }
  .img2 {
    position: absolute;
    margin-left: -14rem;
    width: 16rem;
    margin-top: 4rem;
  }
  .imgmblview {
    width: 42rem;
    margin-top: -30rem;
    margin-left: -0.1rem;
  }
  .imgmblview1 {
    position: absolute;
    width: 42rem;
    margin-left: -0.5rem;
    margin-top: -1rem;
  }
}
