.untext {
  color: #1967ff;
  font-size: 2.2rem;
  font-weight: 500;
  margin-top: 2rem;
}
 
.infotext {
  color: #eb4bb6;
  font-weight: bold;
  font-size: 4.6rem;
  margin-top: 2rem;
}
.infotext1 {
  color: #1967ff;
  font-weight: bold;
  font-size: 4.6rem;
  margin-top: 2rem; 
}
.divinfo {
  display: flex;
  justify-content: center;
  align-items: center; 
}
.divun {
  display: flex;
  justify-content: center;
  align-items: center;
}
.imglog {
  position: absolute;
  margin-left: 18rem;
  margin-top: -2rem;
}
.navbarcon {
  padding-left: 6rem;
  padding-right: 6rem;
}
@media (max-width: 510px) {
  .imglog {
    position: absolute;
    margin-left: 14rem;
    margin-top: -2rem;
    width: 2rem;
  }
  .untext {
    color: #1967ff;
    font-size: 2rem;
    font-weight: 500;
    margin-right: 2rem;
  }
  .infotext {
    color: #eb4bb6;
    font-weight: bold;
    font-size: 3rem;
  }
  .infotext1 {
    color: #1967ff;
    font-weight: bold;
    font-size: 3rem;
  }
  .navbarcon {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
