.headtext2 {
  font-size: 4rem;
  color: #1967ff;
  font-weight: bold;
}

.head1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20rem;
  margin-bottom: 3rem;
}
.pp {
  color: #cdcbd7;
}
.pp2 {
  color: #eb4bb6 !important;
  text-decoration: none;
  pointer-events: none;
}
.inputin4456 {
  padding: 3rem;
  width: 75rem;
  border-radius: 1rem;
  margin-top: 1.5rem;
  background: #efedff;
  border: none;
  font-size: 2rem;
  font-weight: 500;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #efedff !important;
  border: 1px solid #efedff;
  color: rgb(117, 117, 117) !important;
}

.inputin445655 {
  padding: 3rem;
  width: 75rem;
  border-radius: 1rem;
  margin-top: 1.5rem;
  background: #efedff !important;
  border: none;
  font-size: 2rem;
  /* color: rgb(117, 117, 117) !important; */
  font-weight: 500;
}
::placeholder {
  color: rgb(117, 117, 117) !important;
}
.inputin4455 {
  padding: 3rem;
  width: 35rem;
  border-radius: 1rem;
  margin-top: 1rem;
  background: #efedff;
  border: none;
  font-size: 2rem;
  font-weight: 500;
}

.centerinvalid44 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.centerdiv999 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 84rem;
}
.btninscer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  width: 75rem;
  border-radius: 1rem;
  margin-top: 2rem;
  background: #1967ff;
  border: solid 1px #1967ff;
  font-size: 3rem;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
}
.btninscer:disabled {
  opacity: 0.5;
}
@media (max-width: 425px) {
  .inputin4456 {
    padding: 3rem;
    width: 45rem;
    border-radius: 1rem;
    margin-top: 1rem;
    background: #efedff;
    border: none;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .inputin445655 {
    padding: 3rem;
    width: 45rem;
    border-radius: 1rem;
    margin-top: 1.5rem;
    background: #efedff;
    border: none;
    font-size: 1.5rem;
    /* color: rgb(117, 117, 117) !important; */
    font-weight: 500;
  }
  .inputin4455 {
    padding: 3rem;
    width: 21rem;
    border-radius: 1rem;
    margin-top: 1rem;
    background: #efedff;
    border: none;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .centerdiv999 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50rem;
  }
  .btninscer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    width: 45rem;
    border-radius: 1rem;
    margin-top: 2rem;
    background: #1967ff;
    border: solid 1px #1967ff;
    font-size: 2rem;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
  }
  .headtext2 {
    font-size: 3rem;
    color: #1967ff;
    font-weight: bold;
    text-align: center;
  }
}
@media (max-width: 320px) {
  .inputin4456 {
    padding: 3rem;
    width: 35rem;
    border-radius: 1rem;
    margin-top: 1rem;
    background: #efedff;
    border: none;
    font-size: 1.4rem;
    font-weight: 600;
  }
  .inputin445655 {
    padding: 3rem;
    width: 35rem;
    border-radius: 1rem;
    margin-top: 1.5rem;
    background: #efedff;
    border: none;
    font-size: 1.4rem;
    /* color: rgb(117, 117, 117) !important; */
    font-weight: 500;
  }
  .inputin4455 {
    padding: 3rem;
    width: 16rem;
    border-radius: 1rem;
    margin-top: 1rem;
    background: #efedff;
    border: none;
    font-size: 1.4rem;
    font-weight: 600;
  }

  .centerdiv999 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 40rem;
  }
  .btninscer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    width: 35rem;
    border-radius: 1rem;
    margin-top: 2rem;
    background: #1967ff;
    border: solid 1px #1967ff;
    font-size: 2rem;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
  }
  .headtext2 {
    font-size: 3rem;
    color: #1967ff;
    font-weight: bold;
    text-align: center;
  }
  .head1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 0px;
    margin-top: 5rem;
  }
}
