.maindivfooter {
  background: #f2f2fe;
  padding: 10px 0px;
  margin-top: -1rem;
}
.maindivdis33 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.divdis33 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem;
  box-shadow: 0 0 10px #999797;
  border-radius: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background: #ffffff;
  width: 16rem;
  color: #1967ff;
  border: 1px solid #ffffff;
}
.divdis33:hover {
  background-color: #1967ff;
  color: white !important;
  border: solid 1px #1967ff;
}
.divdis2233 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem;
  box-shadow: 0 0 10px #999797;
  border-radius: 1rem;
  width: 16rem;
  background: #ffffff;
  color: #1967ff;
  border: 1px solid #ffffff;
}
.divdis2233:hover {
  background-color: #1967ff;
  color: white !important;
  border: solid 1px #1967ff;
}
.iconbtt {
  margin-top: -0.5rem;
}
.textdis33 {
  /* color: #1967ff; */
  font-size: 1rem;
  font-weight: 400;
  margin-top: 1rem;
}
.textdis233 {
  /* color: #1967ff; */
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: -1rem;
}
.centerfooter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.centerfooter2244 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.centerfooter22 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.infotext22 {
  color: #eb4bb6;
  font-weight: bold;
  font-size: 4rem;
  margin-top: 4rem;
  margin-left: 2rem;
}
.infotext122 {
  color: #1967ff;
  font-weight: bold;
  font-size: 4rem;
  margin-top: 4rem;
}
.textacc {
  color: #1967ff;
  font-weight: bold;
  font-size: 2rem;
  margin-top: 5rem;
  text-align: center;
}
.textacc1 {
  color: #1967ff;
  font-weight: bold;
  font-size: 1.8rem;
  text-align: center;
}
.textacc2 {
  color: #1967ff;
  font-weight: bold;
  font-size: 1.6rem;
  text-align: center;
}
.copyext {
  color: #1967ff;
  font-weight: 500;
  font-size: 1.5rem;
}
.footersub {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.imgimg3456 {
  width: 4rem;
}
.footersub22 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 7rem;
}
.footersubdiv00 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 20rem;
  margin-left: 6rem;
}
.hrhr {
  color: #d4ddfd;
  margin-top: 5rem;
}
.aatext {
  color: #eb4bb6 !important;
  font-weight: bold;
  font-size: 2rem;
  margin-top: 5rem;
  text-align: center;
  text-decoration: none;
}

@media (max-width: 1024px) {
  .footersubdiv00 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 20rem;
    margin-left: 1rem;
  }
}
@media (max-width: 510px) {
  .footersub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 4rem;
  }
  /* .centerfooter2244 {
    display: none !important;
  } */
  .imgfooterlog {
    margin-left: 5rem;
    width: 4rem;
    margin-top: 5rem;
  }
  .infotext22 {
    color: #eb4bb6;
    font-weight: bold;
    font-size: 3rem;
    margin-top: 7rem;
    margin-left: 4rem;
  }
  .infotext122 {
    color: #1967ff;
    font-weight: bold;
    font-size: 3rem;
    margin-top: 7rem;
  }
  .apple {
    width: 3rem;
    margin-top: -0.5rem;
  }
  .divdis33 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem;
    box-shadow: 0 0 10px #999797;
    border-radius: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background: #ffffff;
    width: 14rem;
  }
  .divdis2233 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem;
    box-shadow: 0 0 10px #999797;
    border-radius: 2rem;
    width: 14rem;
    background: #ffffff;
  }
  .textdis33 {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 0.5rem;
  }
  .textdis233 {
    font-size: 1.2rem;
    font-weight: 700;
    margin-top: -1rem;
  }
  .iconbtt {
    margin-top: -0.5rem;
    width: 3rem;
  }
  .footersub22 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 5rem !important;
  }
  .footersubdiv00 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 18rem;
    margin-left: -1rem;
  }
}
@media (max-width: 320px) {
  .footersub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  /* .centerfooter2244 {
    display: none !important;
  } */
  .imgfooterlog {
    margin-left: 7rem;
    width: 3rem;
    margin-top: 5rem;
  }
  .infotext22 {
    color: #eb4bb6;
    font-weight: bold;
    font-size: 2.5rem;
    margin-top: 7rem;
    margin-left: 4rem;
  }
  .infotext122 {
    color: #1967ff;
    font-weight: bold;
    font-size: 2.5rem;
    margin-top: 7rem;
  }
}
