.mainsigin {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.centerSignIn {
  display: flex;
  align-items: center;
  align-items: center;
  flex-direction: column;
}
.centerSignIn33 {
  display: flex;
  align-items: center;
  align-items: center;
  flex-direction: column;
}
.centerSignInd22 {
  display: flex;
  align-items: center;
  align-items: center;
  flex-direction: column;
  margin-top: 12rem;
}
.textacce {
  color: #1967ff;
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}
.textdivti33 {
  display: flex !important;
}
.textti23477 {
  color: #eb4bb6 !important;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  pointer-events: none;
}

.textacce1 {
  color: #eb4bb6 !important;
  margin-top: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  pointer-events: none;
}
.textti77 {
  color: #1967ff;
  font-size: 4.5rem;
  font-weight: bold;
  text-align: left;
}
.textti23577 {
  color: #eb4bb6 !important;
  font-size: 4.5rem;
  font-weight: bold;
  text-decoration: none;
  pointer-events: none;
}
.textti2177 {
  color: rgba(171, 169, 188, 0.8);
  font-size: 2.2rem;
  font-weight: 500;
}
.imgcom66 {
  width: 40rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.inputin99 {
  padding: 2.5rem;
  width: 55rem;
  border-radius: 1rem;
  margin-top: 1rem;
  background: #efedff;
  border: none;
  font-size: 2rem;
  font-weight: 600;
}
.ant-input {
  background: #efedff !important;
}
.ant-input-affix-wrapper {
  background: #efedff !important;
}
.btninvia99 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1967ff;
  color: white;
  padding: 3.5rem;
  border-radius: 1rem;
  border: solid 1px #1967ff;
  margin-top: 3rem;
  width: 55rem;
  font-size: 2.5rem;
  text-align: center;
}

.checkdiv {
  display: flex;
  justify-content: space-between;
  width: 55rem;
  margin-top: 1rem;
}
.checktext {
  color: #252a35;
  font-size: 1.8rem;
  font-weight: 500;
}
.eyesloggo {
  position: absolute;
  width: 3rem;
  margin-left: 45rem;
  margin-top: 12.5rem;
}

@media (max-width: 1024px) {
  .inputin99 {
    padding: 3rem;
    width: 40rem;
    border-radius: 1rem;
    margin-top: 1rem;
    background: #efedff;
    border: none;
    font-size: 2rem;
  }

  .btninvia99 {
    background: #1967ff;
    color: white;
    padding: 3rem;
    border-radius: 1rem;
    border: solid 1px #1967ff;
    margin-top: 2rem;
    width: 40rem;
    font-size: 2rem;
  }
  .checkdiv {
    display: flex;
    justify-content: space-between;
    width: 40rem;
    margin-top: 1rem;
  }
  .textti77 {
    color: #1967ff;
    font-size: 3.5rem;
    font-weight: bold;
    text-align: left;
  }
  .textti23577 {
    color: #eb4bb6;
    font-size: 3.5rem;
    font-weight: bold;
    text-decoration: none;
  }
  .textti2177 {
    color: rgba(171, 169, 188, 0.8);
    font-size: 2rem;
    font-weight: 500;
  }
  .eyesloggo {
    position: absolute;
    width: 3rem;
    margin-left: 32rem;
    margin-top: 14rem;
  }
  .imgcom66 {
    width: 30rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media (max-width: 768px) {
  .textti77 {
    color: #1967ff;
    font-size: 4.5rem;
    font-weight: bold;
    text-align: center;
  }
  .textti2177 {
    color: rgba(171, 169, 188, 0.8);
    font-size: 2.2rem;
    font-weight: 500;
    text-align: center;
  }
  .textdivti33 {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
@media (max-width: 510px) {
  .textti77 {
    color: #1967ff;
    font-size: 3rem;
    font-weight: bold;
    position: absolute;
    margin-top: -90rem;
  }
  .textti23577 {
    color: #eb4bb6;
    font-size: 3rem;
    font-weight: bold;
    text-decoration: none;
  }
  .textti2177 {
    color: rgba(171, 169, 188, 0.8);
    font-size: 1.8rem;
    font-weight: 500;
    position: absolute;
    margin-top: -42rem;
    margin-left: -20.5rem;
  }
  .imgcom66 {
    width: 28rem;
    margin-top: 5rem;
  }
  .checkdiv {
    display: flex;
    justify-content: space-between;
    width: 38rem;
    margin-top: 1rem;
  }
  .mainsigin {
    margin-top: 5rem;
    margin-bottom: 10rem;
  }
}
