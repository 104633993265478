.centerinvalid {
  display: flex;
  align-items: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10rem;
}
.centerinvalid33 {
  display: flex;
  align-items: center;
  align-items: center;
  flex-direction: column;
  margin-top: 6rem;
}
.centerinvalid22 {
  display: flex;
  align-items: center;
  align-items: center;
  flex-direction: column;
}
.textti {
  color: #1967ff;
  font-size: 5rem;
  font-weight: bold;
  margin-top: 12rem;
}
.textti23 {
  color: #eb4bb6 !important;
  font-size: 5rem;
  font-weight: bold;
  text-decoration: none;
  pointer-events: none;
}
.textti2 {
  color: rgba(171, 169, 188, 0.8);
  font-size: 2.3rem;
  font-weight: 500;
}

.textacce1 {
  color: #eb4bb6 !important;
  margin-top: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  pointer-events: none;
}
.imgcom {
  width: 50rem;
}
.inputin {
  padding: 3.5rem;
  width: 60rem;
  border-radius: 1rem;
  margin-top: 1rem;
  background: #efedff;
  border: none;
  font-size: 2rem;
  /* color: rgba(70, 69, 85, 0.3); */
  font-weight: 600;
}
.inputin22 {
  padding: 3.5rem;
  width: 60rem;
  border-radius: 1rem;
  margin-top: 1rem;
  background: #efedff;
  border: none;
  font-size: 2rem;
  /* color: rgba(70, 69, 85, 0.3); */
  height: 22rem;
  font-weight: 600;
}
.btninvia {
  background: #1967ff;
  color: white;
  padding: 3rem;
  border-radius: 1rem;
  border: solid 1px #1967ff;
  margin-top: 3rem;
  width: 60rem;
  font-size: 2.5rem;
}
.btninvia:disabled {
  opacity: 0.5;
}
.textacce {
  color: #1967ff;
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}
.textdivti {
  display: flex !important;
}
.mbl {
  display: none;
}
.mbl333 {
  display: none;
}
@media (max-width: 1024px) {
  .inputin {
    padding: 3rem;
    width: 40rem;
    border-radius: 1rem;
    margin-top: 1rem;
    background: #efedff;
    border: none;
    font-size: 2rem;
    /* color: rgba(70, 69, 85, 0.3); */
  }
  .inputin22 {
    padding: 3rem;
    width: 40rem;
    border-radius: 1rem;
    margin-top: 1rem;
    background: #efedff;
    border: none;
    font-size: 2rem;
    /* color: rgba(70, 69, 85, 0.3); */
    height: 20rem;
  }
  .btninvia {
    background: #1967ff;
    color: white;
    padding: 3rem;
    border-radius: 1rem;
    border: solid 1px #1967ff;
    margin-top: 2rem;
    width: 40rem;
    font-size: 2rem;
  }
  .textti {
    color: #1967ff;
    font-size: 4rem;
    font-weight: bold;
    margin-top: 6rem;
  }
  .textti2 {
    color: rgba(171, 169, 188, 0.8);
    font-size: 2rem;
    font-weight: 500;
  }
  .textti23 {
    color: #eb4bb6;
    font-size: 4rem;
    font-weight: bold;
    text-decoration: none;
  }
}
@media (max-width: 510px) {
  .textti {
    display: none !important;
  }
  .textti2 {
    display: none !important;
  }
  .imgcom {
    width: 30rem;
    margin-top: 2rem;
    margin-bottom: 8em;
  }
  .centerinvalid33 {
    display: flex;
    align-items: center;
    align-items: center;
    flex-direction: column;
    margin-top: 6rem;
  }
  .centerinvalid {
    display: flex;
    align-items: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
  }
  .mbl {
    display: flex;
    font-size: 4rem !important;
    color: #1967ff;
    font-weight: bold;
    text-align: center;
    position: absolute;
    margin-top: -97rem;
  }
  .mbl2 {
    display: flex;
    font-size: 4rem !important;
    color: #eb4bb6;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
  }
  .mbl333 {
    display: flex;
    color: rgba(171, 169, 188, 0.8);
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    margin-top: -91rem;
    position: absolute;
  }
  .centerinvalid {
    display: flex;
    align-items: center;
    align-items: center;
    flex-direction: column;
    margin-top: 22rem;
  }
}
@media (max-width: 375px) {
  .mbl {
    display: flex;
    font-size: 3rem !important;
    color: #1967ff;
    font-weight: bold;
    text-align: center;
  }
  .mbl2 {
    display: flex;
    font-size: 3rem !important;
    color: #eb4bb6;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
  }
  .mbl333 {
    display: flex;
    color: rgba(171, 169, 188, 0.8);
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }
}
