.maincpom22 {
  background-image: url("../../images/Tracciato\ 542.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.bgposss {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  margin-top: -7rem;
}
.headtext00 {
  font-size: 4rem;
  color: #1967ff;
  font-weight: bolder;
  position: absolute;
  margin-top: 15rem;
}
.headtextdiv {
  padding-top: 5rem;
  display: flex;
  justify-content: center;
}
.centerdiv22 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28rem;
}
.card1 {
  display: flex;
  justify-content: start;
  flex-direction: column;
  border-color: #f5f2fd;
  border-radius: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0 10px #999797;
  background: #ffffff;
}
.cardindindiv {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  /* margin-top: .5rem; */
  text-align: center;
  width: 50px;
  height: 15px;
  background-color: #ffff;
  color: #eb4bb6;
}

.cardhead {
  padding-top: 2rem;
  font-size: 4rem;
  font-weight: bolder;
  color: #1967ff;
}
.cardpara {
  color: #1967ff;
  font-size: 1.2rem;
  font-weight: 500;
}
.cardpara1 {
  color: #eb4bb6 !important;
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: none;
  pointer-events: none;
}
.cardbutton {
  padding-bottom: 0.5rem;
  padding-left: 10rem;
  padding-right: 10rem;
  padding-top: 0.5rem;
  border: solid #eb4bb6;
  background-color: #eb4bb6;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  border-radius: 0.75rem;
}
.buttondiv {
  padding-top: 1rem;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.tt1 {
  color: #1967ff;
  font-weight: 700;
  font-size: larger;
  padding-top: 2.25rem;
}
.tt21 {
  color: #1967ff;
  font-size: 1rem;
  margin-bottom: -0.2rem;
  margin-left: 0.5rem;
}
.tt22 {
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
}
.check {
  color: #1967ff;
}
.tt222 {
  color: #1967ff;
  font-size: 1rem;
  padding-left: 1rem;
  margin-bottom: -0.2rem;
}
.tt23 {
  color: #1967ff;
  font-size: 1rem;
  padding-left: 1rem;
  margin-bottom: -0.2rem;
}
.cardbutton2 {
  padding-bottom: 0.5rem;
  padding-left: 12rem;
  padding-right: 12rem;
  padding-top: 0.5rem;
  border: solid #1967ff;
  background-color: #1967ff;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  border-radius: 0.75rem;
}
.cardindindiv2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  width: 50px;
  height: 15px;
  background-color: #ffff;
  color: #1967ff;
}
.card2 {
  padding-left: 2rem;
  padding-right: 4rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-color: #f5f2fd;
  border-radius: 3rem;
  box-shadow: 0 0 10px #999797;
}
.tt24 {
  color: #1967ff;
  font-size: 1rem;
  padding-left: 1rem;
  margin-bottom: -1.75rem;
}
.cardcorner22 {
  width: 7.1rem;
  margin-top: -2rem;
  position: absolute;
  margin-left: 24.9rem;
}
.cardcorner {
  width: 7rem;
  margin-top: -2rem;
  position: absolute;
  margin-left: 22.3rem;
}
.cornerdiv {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1024px) {
  .cardcorner22 {
    width: 7.1rem;
    margin-top: -2rem;
    position: absolute;
    margin-left: 23.9rem;
  }
  .cardcorner {
    width: 7rem;
    margin-top: -2rem;
    position: absolute;
    margin-left: 21.3rem;
  }
  .card1 {
    display: flex;
    justify-content: start;
    flex-direction: column;
    border-color: #f5f2fd;
    border-radius: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 0 10px #999797;
    background: #ffffff;
  }
  .card2 {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-color: #f5f2fd;
    border-radius: 3rem;
    box-shadow: 0 0 10px #999797;
  }
  .cardbutton2 {
    padding-bottom: 0.5rem;
    padding-left: 10rem;
    padding-right: 12rem;
    padding-top: 0.5rem;
    border: solid #1967ff;
    background-color: #1967ff;
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
    border-radius: 0.75rem;
  }
  .headtext00 {
    font-size: 4rem;
    color: #1967ff;
    font-weight: bolder;
    position: absolute;
    margin-top: 12rem;
  }
}
@media (max-width: 768px) {
  .cardcorner22 {
    width: 7.1rem;
    margin-top: -2rem;
    position: absolute;
    margin-left: 23.9rem;
  }
  .cardcorner {
    width: 7rem;
    margin-top: -2rem;
    position: absolute;
    margin-left: 21.5rem;
  }
}
@media (max-width: 510px) {
  .headtext00 {
    font-size: 3rem;
    color: #1967ff;
    font-weight: bold;
    position: absolute;
    margin-top: 5rem;
  }
  .centerdiv22 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14rem;
    margin-bottom: -5rem;
  }
}

@media (max-width: 375px) {
  .cardcorner22 {
    width: 7.1rem;
    margin-top: -1.9rem;
    position: absolute;
    margin-left: 23.9rem;
  }
  .cardcorner {
    width: 7rem;
    margin-top: -2rem;
    position: absolute;
    margin-left: 21.1rem;
  }
}
@media (max-width: 320px) {
  .cardcorner22 {
    width: 7.1rem;
    margin-top: -2rem;
    position: absolute;
    margin-left: 23.9rem;
  }
  .cardcorner {
    width: 7rem;
    margin-top: -2rem;
    position: absolute;
    margin-left: 21.4rem;
  }
}
