.maindiv {
  background-image: url("../../images/Tracciato\ 542.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: -10rem;
  padding: 10px 0px;
}
.imgdone {
  margin-top: 38rem;
}
.doneimg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.centercompotwo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20rem;
}
.centercompotwo22 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 35rem;
}
.circleimg {
  width: 100%;
}
.moblieimg {
  width: 100%;
  position: absolute;
}
.imgimg2 {
  width: 2rem;
  margin-left: -1rem;
}
.imgimg24 {
  width: 2rem;
  margin-left: -0.2rem;
}
.scopdiv {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 2px solid #1967ff;
  border-radius: 1rem;
  box-shadow: 0 0 10px #999797;
  background: #ffffff;
  width: 32rem;
}
.scopdiv00 {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 2px solid #1967ff;
  border-radius: 1rem;
  box-shadow: 0 0 10px #999797;
  background: #ffffff;
  width: 32rem;
}
.scopdiv22 {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 2px solid #1967ff;
  border-radius: 1rem;
  box-shadow: 0 0 10px #999797;
  background: #ffffff;
  width: 32rem;
}
.maindivscop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.textmig {
  color: #252a35;
  font-size: 1.3rem;
  font-weight: bold;
  /* margin-left: 0.5rem;
  margin-top: 1.2rem; */
}
.textmig1 {
  color: #aba9bc;
  font-size: 1rem;
  font-weight: 500;
  margin-top: -0.2rem;
  margin-left: -3rem;
}
.textmig198 {
  color: #aba9bc;
  font-size: 1rem;
  font-weight: 500;
  margin-top: -0.2rem;
}
.marginleftok {
  margin-left: 0rem;
}
.divmigsub {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.divmigsub22 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -2.8rem;
}
.btnduiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  box-shadow: 0 0 10px #999797;
  border-radius: 1rem;
  width: 70rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  background: #ffffff;
  border: solid 1px #ffffff;
}
.textloc {
  color: #1967ff;
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 1rem;
}
.textloc1 {
  color: #1967ff;
  font-size: 1.8rem;
  font-weight: 500;
  margin-top: -0.5rem;
}
.imglogop {
  position: absolute;
  margin-top: -6rem;
  margin-left: 67rem !important;
}
.divseg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #d4ddfd;
  padding-left: 9rem;
  padding-right: 9rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-radius: 1rem;
  margin-top: 10rem;
  margin-bottom: 2rem;
  box-shadow: 0 0 10px #999797;
  border: solid 1px #d4ddfd;
}
.textseg {
  color: #1967ff;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
}
.maindivseg {
  width: 70rem;
}
.textloc233 {
  color: #eb4bb6 !important;
  font-size: 1.8rem;
  font-weight: 500;
  margin-top: -0.5rem;
  text-decoration: none;
}
.imgimg {
  width: 6rem;
}
@media (max-width: 1024px) {
  .scopdiv {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: 2px solid #1967ff;
    border-radius: 1rem;
    box-shadow: 0 0 10px #999797;
    background: #ffffff;
    width: 26rem;
  }
  .scopdiv00 {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: 2px solid #1967ff;
    border-radius: 1rem;
    box-shadow: 0 0 10px #999797;
    background: #ffffff;
    width: 26rem;
  }
  .scopdiv22 {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: 2px solid #1967ff;
    border-radius: 1rem;
    box-shadow: 0 0 10px #999797;
    background: #ffffff;
    width: 26rem;
  }
  .textmig {
    color: #252a35;
    font-size: 1rem;
    font-weight: bold;
    margin-left: 0.5rem;
    margin-top: 1.2rem;
  }
  .maindivscop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 55rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .centercompotwo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20rem;
  }
  .centercompotwo22 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 25rem;
  }
  .btnduiv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    box-shadow: 0 0 10px #999797;
    border-radius: 1rem;
    width: 55rem;
    margin-top: 3rem;
  }
  .imglogop {
    position: absolute;
    margin-top: -6rem;
    margin-left: 47rem !important;
  }
  .maindivseg {
    width: 55rem;
  }
  .divseg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #d4ddfd;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-radius: 1rem;
    margin-top: 10rem;
    margin-bottom: 2rem;
    box-shadow: 0 0 10px #999797;
  }
  .imgdone {
    margin-top: 25rem;
  }
}
@media (max-width: 768px) {
  .centercompotwo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10rem;
  }
  .centercompotwo22 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15rem;
  }
}
@media (max-width: 510px) {
  .maindivseg {
    width: 50rem;
  }
  .centercompotwo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -2rem;
    margin-bottom: 15rem;
  }
  .imgdone {
    margin-top: 4rem;
    margin-bottom: 6rem;
  }
}
@media (max-width: 390px) {
  .scopdiv {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 2px solid #1967ff;
    border-radius: 1rem;
    box-shadow: 0 0 10px #999797;
    background: #ffffff;
    width: 22rem;
  }
  .scopdiv00 {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 2px solid #1967ff;
    border-radius: 1rem;
    box-shadow: 0 0 10px #999797;
    background: #ffffff;
    width: 22rem;
  }
  .scopdiv22 {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 2px solid #1967ff;
    border-radius: 1rem;
    box-shadow: 0 0 10px #999797;
    background: #ffffff;
    width: 22rem;
  }
  .imgimg2 {
    width: 2rem;
  }
  .textmig {
    color: #252a35;
    font-size: 0.9rem;
    font-weight: bold;
    margin-left: 0.3rem;
    margin-top: 1.2rem;
  }
  .textmig1 {
    color: #aba9bc;
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: -0.5rem;
  }
  .textmig198 {
    color: #aba9bc;
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: -0.5rem;
  }
  .maindivscop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 47rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .centercompotwo22 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 5rem;
  }
  .btnduiv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    box-shadow: 0 0 10px #999797;
    border-radius: 1rem;
    width: 47rem;
    margin-top: 3rem;
  }
  .imglogop {
    position: absolute;
    margin-top: -6rem;
    margin-left: 36rem !important;
  }
  .maindivseg {
    width: 42rem;
  }
  .divseg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #d4ddfd;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 1rem;
    margin-top: 10rem;
    margin-bottom: 2rem;
    box-shadow: 0 0 10px #999797;
  }
  .imgimg {
    width: 5rem;
    margin-top: 0.5rem;
  }
  .imgimg33 {
    margin-left: 1.7rem;
  }
  .maindivseg {
    width: 48rem;
  }
}
@media (max-width: 320px) {
  .btnduiv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    box-shadow: 0 0 10px #999797;
    border-radius: 1rem;
    width: 42rem;
    margin-top: 3rem;
  }
  .maindivscop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 42rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .scopdiv {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    border: 2px solid #1967ff;
    border-radius: 1rem;
    box-shadow: 0 0 10px #999797;
    background: #ffffff;
    width: 20rem;
  }
  .scopdiv00 {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 2px solid #1967ff;
    border-radius: 1rem;
    box-shadow: 0 0 10px #999797;
    background: #ffffff;
    width: 20rem;
  }
  .scopdiv22 {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    border: 2px solid #1967ff;
    border-radius: 1rem;
    box-shadow: 0 0 10px #999797;
    background: #ffffff;
    width: 20rem;
  }
  .textmig {
    color: #252a35;
    font-size: 0.9rem;
    font-weight: bold;
    margin-left: 1rem;
    margin-top: 1.2rem;
  }
  .textmig1 {
    color: #aba9bc;
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: -0.5rem;
  }
  .textmig198 {
    color: #aba9bc;
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: -0.5rem;
  }
  .imgimg33 {
    margin-left: 2.5rem;
  }
  .maindivseg {
    width: 40rem;
  }
}
