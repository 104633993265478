.maincompofirt {
  margin-top: 1rem;
  margin-bottom: 5rem;
}
.divcenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 14rem;
}
.divcenter22 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.texttut {
  font-size: 4.5rem;
  color: #1967ff;
  font-weight: bold;
}
.textlor {
  font-size: 2rem;
  font-weight: 400;
  color: #aba9bc;
}
.maindivdis {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.divdis {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem;
  box-shadow: 0 0 10px #999797;
  border-radius: 1rem;
  width: 14rem;
  border: solid 1px #ffffff;
  background: #ffffff;
  color: #1967ff;
}
.divdis:hover {
  background-color: #1967ff; 
  color: white !important;
  border: solid 1px #1967ff;
}

.divdis22 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem;
  box-shadow: 0 0 10px #999797;
  border-radius: 1rem;
  width: 14rem;
  margin-left: 1rem;
  background: #ffffff;
  border: solid 1px #ffffff;
  color: #1967ff;
}
.divdis22:hover {
  background-color: #1967ff;
  color: white !important;
  border: solid 1px #1967ff;
}
.disimg {
  margin-top: -0.5rem;
}
.textdis {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  margin-top: 0.8rem;
}

.textdis2 {
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: -0.7rem;
  text-align: center;
}
.texthai {
  color: #1967ff;
  font-size: 1rem;
  font-weight: 700;
}
.hom2img99 {
  width: 60rem;
}
.mbhome2 {
  display: none;
}
.homimg {
  position: absolute;
  margin-left: -32rem;
  margin-top: 45rem;
  width: 40rem;
}
.text4k {
  color: #1967ff;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: -0.5rem;
  text-align: center;
  margin-left: 5rem;
}
.div4kstar {
  position: absolute;
  margin-top: 86rem;
  margin-left: -42rem;
}
.text4k22 {
  font-size: 2rem;

  margin-left: 5rem;
}

@media (max-width: 1024px) {
  .hom2img99 {
    width: 40rem;
  }
  .homimg {
    position: absolute;
    margin-left: -25rem;
    margin-top: 40rem;
    width: 32rem;
  }
  .texttut {
    font-size: 3.5rem;
    color: #1967ff;
    font-weight: bold;
  }
  .textlor {
    font-size: 1.5rem;
    font-weight: 400;
    color: #aba9bc;
  }
  .divcenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 8rem;
  }
  .div4kstar {
    position: absolute;
    margin-top: 70rem;
    margin-left: -28rem;
  }
  .text4k {
    color: #1967ff;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: -0.5rem;
    text-align: center;
    margin-left: 5rem;
  }
  .text4k22 {
    font-size: 2rem;
    font-weight: 700;
    margin-left: 5rem;
  }
}
@media (max-width: 768px) {
  .hom2img99 {
    width: 40rem;
  }
  .homimg {
    position: absolute;
    margin-left: -22rem;
    margin-top: 32rem;
    width: 28rem;
  }
  .texttut {
    font-size: 3.5rem;
    color: #1967ff;
    font-weight: bold;
    text-align: center;
  }
  .textlor {
    font-size: 1.5rem;
    font-weight: 400;
    color: #aba9bc;
    text-align: center;
  }
  .divcenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15rem;
  }
  .div4kstar {
    position: absolute;
    margin-top: 125rem;
    margin-left: 22rem;
  }
  .maindivdis {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
  .texthai {
    color: #1967ff;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
  }
  .maincompofirt {
    margin-top: 1rem;
    margin-bottom: 5rem;
  }
  .divcenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 5rem;
  }
}
@media (max-width: 510px) {
  .hom2img99 {
    display: none;
  }
  .mbhome2 {
    display: flex;
    width: 40rem;
  }
  .homimg {
    position: absolute;
    margin-left: -20rem;
    margin-top: 8rem;
    width: 28rem;
  }
}
@media (max-width: 375px) {
  .hom2img99 {
    display: none;
  }
  .homimg {
    position: absolute;
    margin-left: -20rem;
    margin-top: 9rem;
    width: 28rem;
  }
  .div4kstar {
    position: absolute;
    margin-top: 128rem;
    margin-left: 22rem;
  }
}
